@use "sass:color"
@import main

body
  font-size: 1.08em
  margin: 0 0 0 12em
  padding: 0
  overscroll-behavior: none

body > article, body > footer
  margin: 0 5em
  padding: 2em 0
  //max-width: 55em

body > article
  max-width: 1000px
  margin: auto

body > header
  display: block
  position: fixed
  background: color.adjust($dark, $lightness: -50%)
  color: #fff
  border: none
  box-shadow: none
  opacity: .98
  margin: 0
  padding: 2.2em 2em
  width: 7em
  top: 0
  bottom: 0
  left: 0

nav
  margin: 0 -2em

nav a
  display: block
  margin: 0
  padding: .5em 2em

nav a.selected
  background: color.adjust($dark, $lightness: 4%)
  text-decoration: inherit

nav a:hover
  background: color.adjust($dark, $lightness: 8%)
  text-decoration: inherit
  transition: 0.3s ease-in-out

article header h1
  font-size: 2em

.icons svg
  margin-right: 1.2em

.hidden
  display: inline-block





.description
  margin: 2em 0


